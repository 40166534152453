import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import front_position from './imgs/scan_instruction/front_position.mp4';

function Scan() {
  const [phase, setPhase] = useState(0);
  const [countdown, setCountdown] = useState(null);
  const [capturedImage1, setCapturedImage1] = useState(null);
  const [capturedImage2, setCapturedImage2] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let stream;
    const videoElement = document.getElementById('video');
    if (phase === 1 || phase === 2) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(mediaStream => {
          stream = mediaStream;
          videoElement.srcObject = stream;
        })
        .catch(error => {
          console.error('Error accessing webcam:', error);
          setError('Unable to access webcam. Please check permissions or device availability.');
        });
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [phase]);

  const handleTakePhoto = () => {
    setCountdown(3);
    let countdownTimer = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(countdownTimer);
          captureImage();
          setCountdown(null);
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleRetakePhoto = () => {
    if (phase === 1) {
      setCapturedImage1(null);
    } else if (phase === 2) {
      setCapturedImage2(null);
    }
    setCountdown(null); // Reset countdown if needed
  };

  const captureImage = () => {
    const videoElement = document.getElementById('video');
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL('image/png');

    if (phase === 1) {
      setCapturedImage1(imageData);
    } else if (phase === 2) {
      setCapturedImage2(imageData);
    }
  };

  return (
    phase === 0 ? (
      <div className="relative">
        <div className="fixed top-0 left-0 w-full h-full z-0">

        </div>
        <div className="relative z-10 text-center">
          <header className="min-h-screen flex flex-col  text-white">
            <Header />

            <p className='mt-[150px] md:mt-[200px] text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl font-helixa-black font-bold'>

            </p>

            <div className="w-full max-w-[600px] pl-5 mx-auto text-left mt-5 text-black text-2xl">
              <p className="text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl typewriter-text line1">
                <div className='flex justify-between'>
                  <div>Put on athletic, <br /><span className="font-bold"> tight clothing</span>.</div>
                  <div className='px-4  flex flex-col justify-center'>1</div>
                </div>
              </p>

              <br />       <br />
              <p className="text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl typewriter-text line2">
                <div className='flex justify-between'>
                  <div>Tuck in any <br /><span className="font-bold">baggy shirts</span>.</div>
                  <div className='px-4  flex flex-col justify-center'>2</div>
                </div>
              </p>
              <br />       <br />
              <p className="text-slate-900 text-4xl sm:text-4xl md:text-5xl lg:text-5xl typewriter-text line3">
                <div className='flex justify-between'>
                  <div>Make a video <br /><span className="font-bold">following poses</span>.</div>
                  <div className='px-4  flex flex-col justify-center'>3</div>
                </div>
              </p>
              <br />       <br />
              <div className="flex space-x-4 mt-8 md:mt-12 text-xl sm:text-2xl">
                <Link onClick={() => { setPhase(1) }} className="relative btn-slide-show font-helixa-regular px-10 md:px-20">
                  <p className="btn-slide-show-text1">Let's go!</p>
                  <div className="btn-slide-show-inner">
                    <p className="btn-slide-show-text2">Next →</p>
                  </div>
                </Link>
              </div>
            </div>
          </header>
        </div >
      </div >
    ) : (
      <div className='absolute w-full h-full bg-white'>
        <div className="text-center mt-4">
          <video
            id="videoElement"
            src={`${front_position}`}
            autoPlay
            muted
            playsInline
            loop
            className='border-2 absolute object-cover opacity-[50%] '


          ></video>
          {countdown && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-red-500">
              {countdown}
            </div>
          )}
          <div className="mt-4 space-x-4">
            {!countdown && phase === 1 && !capturedImage1 && (
              <>
                <video id="video" autoPlay className="border-2 border-black max-w-full mx-auto"></video>
                <button
                  onClick={handleTakePhoto}
                  className="px-6 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-600">
                  Take Photo
                </button>
                {capturedImage1 && (
                  <button
                    onClick={handleRetakePhoto}
                    className="px-6 py-2 bg-orange-500 text-white font-bold rounded hover:bg-orange-600">
                    Retake Photo
                  </button>
                )}
              </>
            )}
            {!countdown && phase === 1 && capturedImage1 && (
              <>
                <button
                  onClick={() => setPhase(2)}
                  className="px-6 py-2 bg-green-500 text-white font-bold rounded hover:bg-green-600">
                  Proceed to Second Photo
                </button>
                <button
                  onClick={handleRetakePhoto}
                  className="px-6 py-2 bg-orange-500 text-white font-bold rounded hover:bg-orange-600">
                  Retake Photo
                </button>
              </>
            )}
            {!countdown && phase === 2 && !capturedImage2 && (
              <>
                <button
                  onClick={handleTakePhoto}
                  className="px-6 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-600">
                  Take Second Photo
                </button>
                {capturedImage2 && (
                  <button
                    onClick={handleRetakePhoto}
                    className="px-6 py-2 bg-orange-500 text-white font-bold rounded hover:bg-orange-600">
                    Retake Photo
                  </button>
                )}
              </>
            )}
            {!countdown && phase === 2 && capturedImage2 && (
              <>
                <button
                  onClick={() => alert('Both photos captured!')}
                  className="px-6 py-2 bg-green-500 text-white font-bold rounded hover:bg-green-600">
                  Finish
                </button>
                <button
                  onClick={handleRetakePhoto}
                  className="px-6 py-2 bg-orange-500 text-white font-bold rounded hover:bg-orange-600">
                  Retake Photo
                </button>
              </>
            )}
          </div>
          {capturedImage1 && (
            <div className="mt-4">
              <p>First Photo:</p>
              <img src={capturedImage1} alt="First capture" className="border-2 border-gray-400 max-w-full" />
            </div>
          )}
          {capturedImage2 && (
            <div className="mt-4">
              <p>Second Photo:</p>
              <img src={capturedImage2} alt="Second capture" className="border-2 border-gray-400 max-w-full" />
            </div>
          )}
          {error && <div className="text-red-500 mt-2">{error}</div>}
        </div>
      </div>
    )
  );
}

export default Scan;
