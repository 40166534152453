import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import jumper from './imgs/jumper.png';
import dance4 from './imgs/dance4.mp4';
import dance from './imgs/dance.mov';
import './index.css';
import LorenzAttractor from './LorenzAttractor';
import GrainBackdrop from './GrainBackdrop';
import Header from './Header';
import Background from './Background';
import SpinningText from './SpinngText';
import { useState } from 'react';
import QRCode from 'qrcode';
import { useEffect } from 'react';

import audioFile from './audio/background.mp3';

import { useRef } from 'react';
import './Background.css'; // Import the CSS file


function App() {

  const [qrCodeUrl, setQrCodeUrl] = useState('');
  useEffect(() => {
    // Generate QR code for the link
    const link = 'https://sizeform.com/scan'; // Replace with your desired link

    QRCode.toDataURL(link, {
      color: {
        dark: '#000000', // Blue color for QR code dots
        light: '#FFFFFF', // White background
      },
    })
      .then((url) => setQrCodeUrl(url)) // Set the generated QR code URL
      .catch((err) => console.error('Error generating QR code:', err)); // Handle errors
  }, []);


  const arr0 = ['#C0FDFB', '#64B6AC', '#FAD4C0', '#FEE9E1', '#B09E99'];
  const arr1 = ['#F9D689', '#FEFFD2', '#FFBF78', '#FFEEA9', '#F0A500'];
  const arr2 = ['#FEA1A1', '#ECCDB4', '#F0EDD4', '#F9FBE7', '#FF7D29'];
  const arr3 = ['#fff5d7', '#ECCDB4', '#ECCDB4', '#ECCDB4', '#ECCDB4'];
  const arr4 = ['#E0BFB8', '#F8C8DC', '#F3CFC6', '#C9A9A6', '#F2D2BD'];
  const arr5 = ['#90f6d7', '#35bcbf', '#FAD4C0', '#FEE9E1', '#B09E99'];

  const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const startLink = !isMobile ? '/link' : '/scan';

  // Array of taglines with specific word italicized
  const taglines = {
    0: { textBefore: "", italic: "feel good", textAfter: "sunday" }, // Sunday
    1: { textBefore: "made for", italic: "you", textAfter: "monday" }, // Monday
    2: { textBefore: "", italic: "tailored", textAfter: "tuesday" }, // Tuesday
    3: { textBefore: "hump", italic: "fitting", textAfter: "day" }, // Wednesday
    4: { textBefore: "", italic: "tailored", textAfter: "thursday" }, // Thursday
    5: { textBefore: "", italic: "perfect", textAfter: "fit friday" }, // Friday
    6: { textBefore: "", italic: "slay saturday", textAfter: "" } // Saturday
  };

  // Get the current day of the week (0 is Sunday, 6 is Saturday)
  const currentDay = new Date().getDay();
  const { textBefore, italic, textAfter } = taglines[currentDay];

  const [sessionID, setSessionID] = useState('')

  const setQR = () => {
    console.log(sessionID)
    setSessionID('XYZ')
  }

  useEffect(() => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      video.playbackRate = 0.9; // Adjust playback speed (0.5 for half speed)
    });
  }, []);

  const audioRef = useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };



  return (
    <div className="relative w-full h-full z-0" onClick={playAudio} onScroll={playAudio}>
      <audio ref={audioRef} hidden>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <div className='fixed'>
        <div className="relative top-0 left-0 w-full h-full z-0 opacity-100">

          <video
            id="videoElement"
            src={`${dance4}`}
            autoPlay
            muted
            playsInline
            loop
            className='absolute w-full h-full object-cover opacity-[100%] hue-rotate-[0deg]'


          >
          </video>

          <div className='opacity-30'>
            <Background />
          </div>


        </div>
      </div>

      <div className="relative z-10 text-center">
        <header className="min-h-screen flex flex-col items-center justify-center text-white">
          <Header className="z-50" onClick={() => { console.log("hey") }} />

          {sessionID === "" ? <div className=' p-10'>
            <div className='md:px-5 text-slate-900 text-5xl sm:text-5xl md:text-6xl lg:text-7xl font-helixa-regular text-center'>
              <div className='font-normal text-2xl sm:text-3xl mt-[0px]'>Clothing in the</div>
              <div className='font-helixa-black text-[40px] sm:text-5xl md:text-6xl lg:text-7xl'>
                Image of you
              </div>
              {/* {textBefore} <i>{italic}</i> {textAfter} */}
            </div>
            <div className='flex space-x-4 mt-16 text-xl sm:text-2xl justify-center'>
              {isMobile ? <>
                <Link to="/scanR" className="relative btn-slide-show font-helixa-regular px-10 md:px-[150px]">
                  <p className="btn-slide-show-text1">Let's go!</p>
                  <div className="btn-slide-show-inner">
                    <p className="btn-slide-show-text2">Start →</p>
                  </div>
                </Link></> : <>
                <Link onClick={setQR} className="relative btn-slide-show font-helixa-regular px-10 md:px-[150px]">
                  <p className="btn-slide-show-text1">Let's go!</p>
                  <div className="btn-slide-show-inner">
                    <p className="btn-slide-show-text2">Start →</p>
                  </div>
                </Link></>}


            </div>
          </div> : <div>
            <img src={qrCodeUrl} className='w-[200px] rounded-xl  border-2 border-black z-10' alt="QR Code" />
            <div className='font-helixa-black text-black text-3xl mt-4'>
              Scan me.
            </div>
          </div>}
        </header>


      </div>
    </div >
  );
}

export default App;
