import React from 'react';

const SpinningText = ({ diameter, opacity, speed, phase }) => {
  return (
    <div className={`spinning-container absolute`} style={{ rotate: `${phase}deg`, opacity: opacity / 100, animation: `spin ${speed}s linear infinite` }}>
      <svg
        width={`${diameter}px`}
        height={`${diameter}px`}
        viewBox="0 0 200 200" id="rotatingText">
        <defs>
          <path
            id="circle"
            d="M 100, 100
              m -75, 0
              a 75, 75 0 1, 0 150, 0
              a 75, 75 0 1, 0 -150, 0"
          />
        </defs>
        <text width="400" style={{ fontFamily: 'Helixa', fontWeight: '900' }}>
          <textPath
            alignmentBaseline="top"
            href="#circle"
            style={{ fontFamily: 'Helixa', fontWeight: '900', letterSpacing: '0.45em', fontSize: '1.5em', fill: 'blue' }}
          >
            ..........................
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default SpinningText;