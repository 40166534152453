import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Scan from './Scan';
import ScanR from './ScanR';
import Partners from './Partners';
import LinkPhone from './LinkPhone';

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ScanR />} />
        <Route path="/link" element={<LinkPhone />} />
        <Route path="/scan" element={<Scan />} />

        <Route path="/scanR" element={<ScanR />} />
        <Route path="/about" element={<Partners />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;