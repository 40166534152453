import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import QRCode from 'qrcode';
import './index.css';
import Header from './Header';
import SpinningText from './SpinngText';
import lock from './imgs/lock.png';

const socket = io('http://localhost:3001'); // Replace with your server URL

function LinkPhone() {


  const [qrCodeUrl, setQrCodeUrl] = useState('');
  useEffect(() => {
    // Generate QR code for the link
    const link = 'https://sizeform.com/scan'; // Replace with your desired link

    QRCode.toDataURL(link, {
      color: {
        dark: '#000000', // Blue color for QR code dots
        light: '#FFFFFF', // White background
      },
    })
      .then((url) => setQrCodeUrl(url)) // Set the generated QR code URL
      .catch((err) => console.error('Error generating QR code:', err)); // Handle errors
  }, []);


  return (
    <div className="relative">
      <div className="fixed top-0 left-0 w-full h-full z-0 opacity-60 bg-blue-100/5 flex justify-center hue-rotate-[-30deg]">
        <SpinningText diameter={825} opacity={15} speed={250} phase={-28} />
        <SpinningText diameter={1000} opacity={20} speed={200} phase={-14} />
        <SpinningText diameter={1200} opacity={35} speed={150} phase={0} />
        <SpinningText diameter={1600} opacity={40} speed={125} phase={14} />
      </div>

      <div className="relative z-10 text-center">
        <header className="min-h-screen flex flex-col items-center justify-center text-white">
          <Header />
          {qrCodeUrl ? (
            <div>
              <img src={qrCodeUrl} className='w-[200px] rounded-xl  border-2 border-black' alt="QR Code" />
            </div>
          ) : (
            <p className='text-slate-900 text-xl'>Loading...</p>
          )}

          <p className="md:px-5 text-slate-900 text-5xl sm:text-5xl md:text-6xl mt-5 font-helixa-regular font-bold text-center">
            <span className="font-helixa-regular">
              {/* <img
                src={lock}
                className="w-[45px] inline-block pb-[8px] mr-3"
                alt="Lock Icon"
              /> */}
              <b className='font-helixa-black'> Link   phone</b>
            </span>
            <div className="text-3xl mt-5">to continue.</div>
          </p>
        </header>
      </div>
    </div>
  );
}

export default LinkPhone;
