import React, { useEffect } from 'react';
import grained from './grained';
import backgroundImg from './imgs/background.png'; // Import the image

const GrainBackdrop = () => {
  useEffect(() => {
    grained('#myElement', {
      animate: true,
      patternWidth: 1,
      patternHeight: 10,
      grainOpacity: 0.03,
      grainDensity: 10,
      grainWidth: 1,
      grainHeight: 5,
      grainChaos: 1,
      grainSpeed: 2
    });
  }, []);

  const grainContainerStyle = {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden' // Ensure the image covers the viewport fully
  };

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1 // Position the image behind the grain effect
  };

  return (
    <div id="myElement" style={grainContainerStyle}>

    </div>
  );
};

export default GrainBackdrop;
