import React, { useState, useRef, useEffect } from "react";
import dance2 from "./imgs/scan_instruction/front_position.webm";
import demo2 from "./imgs/scan_instruction/front_position_woman.mp4";
import front_pose_img from "./imgs/scan_instruction/front_position.png";
import Background from "./Background";
import { ring, pinwheel, grid } from "ldrs";
import orientation from "./imgs/scan_instruction/orientation.png";
import Header from './Header';

ring.register();
pinwheel.register();
grid.register();

const ScanR = () => {

  const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [cam_disabled, setCamDisabled] = useState(false);
  const [connecting_camera, setConnectingCamera] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [processingScan, setProcessingScan] = useState(false);

  const [ratios, setRatios] = useState({})

  const updateRatios = (e) => {
    const multiplier = parseFloat(e.target.value); // Convert the input value to a number
    if (isNaN(multiplier)) return; // Guard against invalid input

    const multiplyValues = (obj, multiplier) => {
      const newObj = { ...obj }; // Create a shallow copy of the object
      Object.keys(newObj).forEach(key => {
        if (typeof newObj[key] === 'number') { // Ensure the value is a number
          newObj[key] *= multiplier;
        }
      });
      return newObj;
    };

    console.log(e.target.value);
    setRatios(multiplyValues(aiResults, multiplier));
  };



  const videoRef = useRef(null);

  const handleEnableCamera = async () => {
    setConnectingCamera(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraEnabled(true);
      setCamDisabled(false);
      setConnectingCamera(false);

      // Set the video stream to the 4video element
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      console.log("Camera access granted:", stream);
    } catch (error) {
      console.error("Error accessing camera:", error);
      setCamDisabled(true);
      setConnectingCamera(false);
    }
  };

  const checkOrientation = () => {
    if (window.innerWidth > window.innerHeight) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  };

  useEffect(() => {
    // Check orientation on mount and on window resize
    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  const [countdown, setCountdown] = useState(0);
  const instructionalPoseRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [capturedImageSecondary, setCapturedImageSecondary] = useState(null);

  let firstImg = null;
  let secondImg = null;


  const [countdownSecondary, setCountdownSecondary] = useState(0);


  const [turn60, setTurn60] = useState(false);
  const [coverTextTop, setCoverTextTop] = useState("HOLD FIELD");
  const [coverTextBottom, setCoverTextBottom] = useState("GOAL POSE");
  let takingSide = false;
  const handleTakeFrontPhoto = () => {
    setCapturedImage(null)
    setCountdown(12);
    setCoverTextTop("HOLD FIELD");
    setCoverTextBottom("GOAL POSE");

    const interval = setInterval(() => {
      setCountdown((prev) => {
        console.log(prev); // Log the current value of countdown

        if (prev <= 9 && prev >= 7 && turn60 === false) {
          setTurn60(true)

        } else {
          setTurn60(false)
        }

        if (prev <= 1) {
          console.log("Say cheese!");

          // Capture the photo from the video feed
          if (videoRef.current) {
            const canvas = document.createElement("canvas");
            const video = videoRef.current;

            // Set canvas dimensions to match the video
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the current frame from the video onto the canvas
            const context = canvas.getContext("2d");
            if (context) {
              context.drawImage(video, 0, 0, canvas.width, canvas.height);

              // Convert canvas to image data URL
              const photoDataUrl = canvas.toDataURL("image/png");

              // Save the captured image to state
              setCapturedImage(photoDataUrl);
              console.log("first", photoDataUrl)
              firstImg = photoDataUrl;
              setCountdown(0);
              console.log(takingSide)
              if (prev === 1 && !takingSide) {

                takingSide = true;
                handleTakeSidePhoto();
                return;
              }

            }
          }

          clearInterval(interval);
          return prev; // Return the current value to prevent changes
        } else {
          return prev - 1;
        }
      });
    }, 1000);
  };

  const [doingSidePhoto, setDoingSidePhoto] = useState(false);

  const handleTakeSidePhoto = () => {
    console.log("WHADDUP")
    setCapturedImageSecondary(null)
    setCountdownSecondary(11);
    setCoverTextTop("TURN");
    setCoverTextBottom("45 DEGREES");

    const interval = setInterval(() => {
      setCountdownSecondary((prev) => {
        console.log(prev > 7, prev, turn60 === false)
        if (prev > 7 && turn60 === false) {
          setTurn60(true)

        } else {
          setTurn60(false)
          setDoingSidePhoto(true) //! jerry rigged to hide the captured title
        }
        console.log(prev); // Log the current value of countdown
        if (prev <= 1) {
          console.log("Say cheese!");

          // Capture the photo from the video feed
          if (videoRef.current) {
            const canvas = document.createElement("canvas");
            const video = videoRef.current;

            // Set canvas dimensions to match the video
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the current frame from the video onto the canvas
            const context = canvas.getContext("2d");
            if (context) {
              context.drawImage(video, 0, 0, canvas.width, canvas.height);

              // Convert canvas to image data URL
              const photoDataUrl = canvas.toDataURL("image/png");

              // Save the captured image to state
              setCapturedImageSecondary(photoDataUrl);
              secondImg = photoDataUrl;
              setCountdownSecondary(0);
              console.log("secondary", photoDataUrl)
              setProcessingScan(true);
              setTurn60(true)
              getResults()

            }
          }


          clearInterval(interval);
          return prev; // Return the current value to prevent changes
        } else {
          return prev - 1;
        }
      });
    }, 1000);
  }

  const [aiResults, setAiResults] = useState("");

  let commenceResults = false;
  const getResults = async () => {
    if (!commenceResults) {
      setTurn60(true);
      commenceResults = true;
    }
    else { return; }
    setCoverTextTop("Nice job!");
    setCoverTextBottom("Processing...");


    console.log("TURN  60 TRUE AND COVER TEXT SET WTF")

    // Ensure images are captured before processing
    console.log("capturedImage, capturedImageSecondary")
    console.log(firstImg, secondImg)
    if (!firstImg || !secondImg) {
      console.error("Images not captured properly.");
      return;
    }

    try {

      // Prepare the payload for the API request
      const payload = {
        height: "1.0", // Set height to 1.0 for percentage results, must be floating point value
        images: [
          {
            data: firstImg.split(",")[1], // Remove data URL prefix
            fileName: "front.png",
          },
          {
            data: secondImg.split(",")[1], // Remove data URL prefix
            fileName: "side.png",
          },
        ],
      };

      // Make the API request
      const response = await fetch("https://api.snapmeasureai.com/smpl/v2/analyze", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();

      console.log("Body Scan Results:", result);

      const measurementsJsonString = result.data

      const validJsonString = measurementsJsonString.replace(/'/g, '"');
      const jsonObject = JSON.parse(validJsonString);
      console.log(jsonObject);

      setAiResults(jsonObject);

      // Process and display the results
      setCoverTextTop("Nice job!");
      setCoverTextBottom("Getting results.");

      // Handle results (e.g., display or store them)
      // Example: You can set the results to state for rendering
      console.log(result.data);
    } catch (error) {
      console.error("Error during body scan:", error);
      setCoverTextTop("Error");
      setCoverTextBottom("Try Again");
    }
  };



  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  const handleLoadedMetadata = (videoRef) => {
    if (videoRef.current) {
      videoRef.current.currentTime = 3; // Set start time to 3 seconds
    }
  };

  const handleTimeUpdate = (videoRef) => {
    if (videoRef.current) {
      // If video reaches the end, reset it to the 3-second mark
      if (videoRef.current.currentTime >= videoRef.current.duration) {
        videoRef.current.currentTime = 3;
        videoRef.current.play();
      }
    }
  };


  return (
    <div className="w-full h-full">
      <div className={`fixed top-0 left-0 w-full z-[999] ${cameraEnabled ? "hidden" : ""}`}>
        <Header /></div>
      {isLandscape && isMobile && (
        <div className="fixed top-0 left-0 w-full z-[999] h-full flex items-center justify-center bg-black  text-white text-center flex-col">
          <img src={orientation} alt="Rotate device to portrait mode" className="invert w-[200px] mb-10" />
          <div className="text-3xl font-bold font-helixa-regular">

            {/* Use <span className="font-helixa-black">portrait mode</span> */}

            <div className="flex flex-row justify-center">
              <div>
                <l-pinwheel
                  size="35"
                  stroke="3.5"
                  speed="0.9"
                  color="white"
                ></l-pinwheel>
              </div>
              <div className="pl-4">
                Rotate device...</div>

            </div></div>
        </div>
      )}
      <div className="fixed w-full h-full z-50 flex flex-col justify-center">
        <div>
          <div className="mx-auto w-fit mt-[0px] text-5xl font-helixa-regular">
            Tailor
          </div>
          <div className="mx-auto w-fit mt-[10px] text-6xl font-helixa-black">
            Yourself
          </div>
          {!cameraEnabled ? (
            <div className="flex space-x-4 mt-16 text-2xl justify-center px-6">
              <button
                onClick={handleEnableCamera}
                className={`relative btn-slide-show font-helixa-regular px-[150px] ${connecting_camera ? "hidden" : ""
                  }`}
              >
                <p className="btn-slide-show-text1">Let's go!</p>
                <div className="btn-slide-show-inner">
                  <p className="btn-slide-show-text2">Bodyscan →</p>
                </div>
              </button>
              <div className={`${connecting_camera ? "" : "hidden"}`}>

                <l-grid
                  size="80"
                  stroke="5"
                  bg-opacity="0"
                  speed="2"
                  color="black"
                ></l-grid>
              </div>
            </div>
          ) : (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white">
              <div className="relative max-w-[1000px] w-[100%]">
                <div className="text-black text-center text-3xl font-helixa-black mt-[20px] mb-[20px]">
                  {capturedImage && countdown <= 1 && (!doingSidePhoto) ? <>Captured.</> : <></>}
                  {!capturedImage && countdown <= 0 ? <>Get body into frame</> : <></>}

                  {capturedImageSecondary && doingSidePhoto ? <>Captured.</> : <></>}
                  {!capturedImageSecondary && doingSidePhoto ? <>Keep field goal pose</> : <></>}

                  {countdown > 0 ? <>Get body into frame</> : <></>}
                </div>
                <div className="relative w-auto">
                  <div className="relative w-full">
                    <video
                      id="cameraFeed"
                      ref={videoRef}
                      className="w-full h-auto object-contain grayscale scale-x-[-1]"
                      autoPlay
                      playsInline
                      muted
                    />
                  </div>

                  {capturedImage && !doingSidePhoto ? (

                    <img
                      src={capturedImage}
                      alt="Captured pose"
                      className="absolute inset-0 w-[100%] mx-auto grayscale scale-x-[-1]"
                    />

                  ) : <></>}
                  {capturedImageSecondary && doingSidePhoto ? (

                    <img
                      src={capturedImageSecondary}
                      alt="Captured pose"
                      className="absolute inset-0 w-[100%] mx-auto grayscale scale-x-[-1]"
                    />

                  ) : <></>}
                  <div className="absolute inset-0 bg-[#ffbcbf] opacity-100 pointer-events-none mix-blend-multiply"></div>
                  <div className={`absolute inset-0 text-white text-6xl font-helixa-black flex flex-col justify-center ${countdown > 0 || countdownSecondary > 0 ? "hidden" : ""}`}><div className="text-center hidden">Place phone<br />for body scan</div></div>
                  <div className={`absolute inset-0 text-white text-6xl font-helixa-black flex flex-col justify-center ${!countdownSecondary > 0 ? "hidden" : ""}`} ><div className="text-center hidden">{coverTextTop}<br />{coverTextBottom}</div></div>
                  <div className={`z-50 font-helixa-black text-white absolute top-0 inset-0 text-9xl text-center flex flex-col justify-center ${countdown === 0 ? "hidden" : ""}`}><>{countdown}</></div>
                  <div className={`z-50 font-helixa-black text-white absolute top-0 inset-0 text-9xl text-center flex flex-col justify-center ${countdownSecondary === 0 || !doingSidePhoto ? "hidden" : ""}`}><>{countdownSecondary}</></div>


                </div>
                <div className={`fixed top-0 left-0 inset-0 bg-white text-black text-6xl font-helixa-black flex flex-col 
                  justify-center z-[999] text-center ${turn60 ? "h-full" : "h-[0px]"}`} style={{ transition: "height 1s ease" }}><div className={`${turn60 ? "opacity-100" : "opacity-0"}`} style={{ transition: "opacity 1s ease" }}>
                    {!processingScan ? <div> {coverTextTop}<br />{coverTextBottom} </div> : <div> <div className="text-black font-helixa-regular mb-5 text-3xl">Generating sizeform</div>     <l-grid
                      size="80"
                      stroke="5"
                      bg-opacity="0"
                      speed="2"
                      color="black"
                    ></l-grid> </div>}
                    {aiResults !== "" ? <div className="fixed inset-0 text-lg mt-3 font-helixa-regular p-2 top-0 h-full w-full bg-white text-black"> {JSON.stringify(ratios)} <input className="w-30 border-2 border-black" onChange={updateRatios} /></div> : <></>}
                  </div></div>





                <div className={`flex space-x-4 text-2xl justify-center h-[60px]`}>
                  <button
                    onClick={() => { handleTakeFrontPhoto(); }}
                    className={`relative flex justify-center w-full px-5 py-2 text-white font-helixa-regular ${countdown > 0 || countdownSecondary > 0 ? "hidden" : ""}`}
                  >
                    <div className={`${!capturedImage ? "bg-black text-white" : "bg-white text-white"} w-[100%]  text-center h-full flex-col justify-center flex rounded-full`}>
                      {countdown > 0 || countdownSecondary > 0 ? <div className="flex justify-center mt-10"></div> : "Ready"}  →
                    </div>
                  </button>

                  {countdown > 0 || countdownSecondary > 0 ? <div className="mt-2"> <l-grid
                    size="60"
                    stroke="5"
                    bg-opacity="0"
                    speed="2"
                    color="black"
                  ></l-grid> </div> : <></>}

                </div>


                {/* <div className="absolute top-4 left-4 text-2xl text-center font-bold font-helixa-regular">
                  Camera connected.
                </div> */}

              </div>

            </div>
          )}

          <div
            className={`text-center w-full text-2xl mt-10 text-red-600/80 ${!cam_disabled ? "hidden" : ""
              }`}
          >
            Camera is <b>disabled</b>.
          </div>
        </div>
      </div>
      <div className="fixed w-full h-full flex justify-center items-center">
        <video
          ref={videoRef1}
          id="videoElement1"
          src={`${demo2}`}
          autoPlay
          muted
          playsInline

          className="absolute w-auto max-h-screen object-contain hue-rotate-[170deg]"
          onLoadedMetadata={() => handleLoadedMetadata(videoRef1)}
          onTimeUpdate={() => handleTimeUpdate(videoRef1)}
        />

      </div>
    </div >
  );
};

export default ScanR;
